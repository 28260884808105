<footer class="m-grid__item m-footer mini-footer text-center m-footer__wrapper mt-3">
    <!-- TODO refactor -->
    <img
        *ngIf="guidData?.bsid == 1 && guidData?.reservationType != 'WIDGET_VOUCHER'"
        width="250px"
        src="/assets/app/media/img/footer-g.svg"
        alt="Asistenca Zdravje"
    />
    <img *ngIf="guidData?.bsid == 7" width="250px" src="/assets/app/media/img/vzajemna-logo-white.svg" alt="Vzajemna" />
    <img *ngIf="guidData?.bsid == 9" width="200px" src="/assets/app/media/img/sava-logo.png" alt="Zavarovalnica Sava" />
    <img *ngIf="guidData?.bsid == 24" width="200px" src="/assets/app/media/img/logotip-prva.svg" alt="Prva" />
    <img *ngIf="guidData?.bsid == 23" width="100px" src="/assets/app/media/img/logo-vita.png" alt="Vita" />
    <img
        *ngIf="guidData?.vendor == 'infonet'"
        width="120px"
        src="/assets/app/media/img/SRC-infonet-logo.svg"
        alt="SRC Infonet"
        style="fill: white;"
    />

    <img
        *ngIf="guidData?.vendor == 'vizija'"
        width="200px"
        src="/assets/app/media/img/vizija-logo.png"
        alt="Nova vizija"
        style="fill: white;"
    />

    <img
        *ngIf="guidData?.vendor == 'audax'"
        width="120px"
        src="/assets/app/media/img/audax-logo-lg.png"
        alt="Audax"
        style="fill: white;"
    />

    <img
        *ngIf="guidData?.bsid == 1 && guidData?.reservationType == 'WIDGET_VOUCHER'"
        width="120px"
        src="/assets/app/media/img/medifit_medical-01.svg"
        alt="Medifit Medical"
    />
    <img
        *ngIf="!guidData?.bsid && guidData?.contractorId == 'Tk5Sm8bhhuOk9UCTyLP1jKA'"
        width="120px"
        src="/assets/app/media/img/medifit_medical-01.svg"
        alt="Medifit Medical"
    />

    <img
        *ngIf="!guidData?.bsid && guidData?.contractorId == '8fHbW-vr6JvlAWiOwvZIX9k'"
        width="180px"
        src="/assets/app/media/img/med-over-net-logo-white.png"
        alt="Med.Over.Net"
        style="fill: white;"
    />

    <img
        *ngIf="!guidData?.bsid && guidData?.contractorId == 'zs6R3juIpo_JKyBhxhBLvz0'"
        width="180px"
        src="/assets/app/media/img/eSOS_logo.png"
        alt="eSOS"
    />

    <img
        *ngIf="guidData?.bsid == 111 && guidData?.contractorId == 'wcG91OeLDxlg6v18reDBYOQ'"
        width="100px"
        src="/assets/app/media/img/Juventina_logo_BABY.svg"
        alt="Juventina Baby"
    />

    <p *ngIf="!guidData?.bsid" class="text-gray-custom m--visible-desktop">v{{ version }}</p>
</footer>
