export const environment = {
    VERSION: require('../../package.json').version,
    production: true,
    RELEASE_STAGE: 'staging',

    service: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        apiPort: '',
        apiUrl: '/booking/patient/api',
        erpApiUrl: '/booking/erp/api',
        communicationapi: '/booking/erp/communicationapi',
    },
    patient: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/patient/api',
    },
    telemed: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/erp/telemed',
    },
    ePosvet: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/erp/eposvet',
    },
    agora: {
        appId: '5904be04ed014eef8ddfdd45c0035e61',
        logLevel: 0,
        proxyServer: 'eposvet-proxy.eambulanta.si',
        turnServer: {
            turnServerURL: '18.184.90.216',
            username: 'medifit',
            password: '123krneki567',
            udpport: '3478',
            tcpport: '3478',
            forceturn: false,
        },
    },
    webSocketTelemed: {
        protocol: 'wss://',
        rootUrl: 'test-app.eambulanta.si/booking',
        port: '',
        url: '/ws/telemed/',
    },
    document: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/erp/docapi',
    },
    printserver: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/eav2/erp/papi',
    },
};
